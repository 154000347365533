import { ReactNode } from 'react';
import { cn } from '~/lib/utils';

interface HeaderProps {
  children: ReactNode;
  className?: string;
}

export default function Header({ children, className }: HeaderProps) {
  return <header className={cn(`flex min-h-14 px-2 shadow-md bg-white items-center justify-between fixed top-0 left-0 right-0 z-40`, className)}>{children}</header>;
}
